@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Abel&family=Rubik&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@900&display=swap");

h1 {
  font-family: "Lato", sans-serif;
  font-weight: bold;
}
h3,
p {
  font-family: "Abel", sans-serif;
}
.contentBlock {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
